import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../layouts'

import AboutSidenav from "../../partials/about-sidenav"

import '../../scss/about.scss'

const LogosAndTemplates = ({data}) => {
  return (
    <Layout>
      <div className="about partners">

        <Helmet>
          <title>DigiWells – Logos and teplates</title>
          <meta property="og:title" content="DigiWells – Logos and Templates" />
          <meta name="description" content="These high-quality logos for DigiWells are for press use" />
          <meta property="og:description" content="These high-quality logos for DigiWells are for press use" />
          {
            /*
            <meta name="image" content={image} />
            <meta property="og:image" content={image} />
            */
          }
        </Helmet>

        <div className="container">
          <AboutSidenav />

          <div className="about-content">
            <div className="about-content-text">
            <h1 className="page-title">Logos and templates</h1>
              <h2>Logos</h2>
              <p>
              These high-quality logos for DigiWells are for press use. (Right click to save the file)
              </p>
            </div>
            <div className="logo-wrapper">
              <img className="logo" alt="DigiWells logo" src="/digiwells_logo.png" />
              <img className="logo" alt="DigiWells logo (negative)" src="/digiwells_logo_negative.png" />
            </div>
            <h3>Partner Logos</h3>
            <p><a href='/about/all-logos-plain/'>--== Get all partner logos for your slide here ==--</a></p>
            <div className="about-content-text">
              <h2>Presentation templates</h2>
              <p>
              Use this PowerPoint template for presentations from DigiWells.
              Last updated 30/11/2021.
              </p>
              <p>
                <a href="/digiwells-template-update.pptx">Download template</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default LogosAndTemplates
